import React from 'react';
import styled from "styled-components";
import { removeEmoji } from "../../../util";

const Wrapper = styled.div`
  position: relative;
  width: 6.25in;
  height: 4.25in;
  margin: 0;
  padding: 0;
  border: 1px solid black;
  box-shadow: 3px 3px 5px #ccc;
  flex-shrink: 0;
  transform: scale(.8);
  margin: -40px -60px;
  @media (max-width: 650px) {
    transform: scale(.55);
    margin: -100px -130px;
  }
`;
const SafeArea = styled.div`
  position: absolute;
  width: 5.875in;
  height: 3.875in;
  left: 0.1875in;
  top: 0.1875in;
`;
const Text = styled.div`
  margin: 10px;
  width: 224px;
  height: calc(3.875in - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  border-right: 2px dotted #888;
  padding-right: 16px;
  line-height: 1.4em;
`;

const PostcardBackMock = ({ text, valediction, font_name, font_color, font_size }) => (
  <Wrapper>
    <SafeArea>
      <Text style={{ fontFamily: font_name, color: font_color, fontSize: `${font_size}px` }}>
        <div>{removeEmoji(text)}</div>
        <div style={{ textAlign: "right", width: "100%" }}>{removeEmoji(valediction)}</div>
      </Text>
    </SafeArea>
  </Wrapper>
);
export default PostcardBackMock;
import React from 'react';
import styled from "styled-components";
import ColorPicker from 'material-ui-color-picker';

import PostcardBackMock from "./PostcardBackMock";

import { TextField, RadioGroup, Radio, FormControlLabel, Slider } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
`;
const FormWrapper = styled.div`
  flex: 1;
  margin-right: 80px;
  min-width: 250px;
  @media (max-width: 650px) {
    margin: 0 0 40px 0;
  }
`;
const FormLineWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  label {
    margin-right: 20px;
  }
`;

function PostcardBack({ postcardBack, setPostcardBack }) {
  const handleChange = (name, value) => {
    const newBack = Object.assign({ ...postcardBack }, {
      [name]: value
    });
    setPostcardBack(newBack);
  }

  return (
    <Wrapper>
      <FormWrapper>
        <TextField
          label="Postcard Note"
          value={postcardBack.postcard_text}
          onChange={(e) => handleChange('postcard_text', e.target.value)}
          multiline
          rows={5}
          variant="outlined"
          style={{ width: "100%", fontSize: "14px" }}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Closing Line"
          value={postcardBack.postcard_valediction}
          onChange={(e) => handleChange('postcard_valediction', e.target.value)}
          variant="outlined"
          style={{ width: "100%", fontSize: "14px", marginTop: "20px" }}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <RadioGroup row
          aria-label="font_name" name="font_name" 
          value={postcardBack.font_name}
          onChange={(e) => handleChange('font_name', e.target.value)}
          style={{ marginTop: "20px" }}
        >
          <FormControlLabel
            value="Patrick Hand" control={<Radio size="small" />}
            label={<span style={{ fontFamily: "Patrick Hand" }}>Handwriting</span>}
          />
          <FormControlLabel
            value="Sacramento" control={<Radio size="small" />}
            label={<span style={{ fontFamily: "Sacramento" }}>Script</span>}
          />
          <FormControlLabel
            value="Quicksand" control={<Radio size="small" />}
            label={<span style={{ fontFamily: "Quicksand" }}>Modern</span>}
          />
        </RadioGroup>

        <FormLineWrapper>
          <FlexWrap style={{ flex: 1 }}>
            <label>Size</label>
            <Slider
              aria-labelledby="fontsize-slider"
              step={4}
              marks
              value={postcardBack.font_size}
              onChange={(e,v) => handleChange("font_size", v)}
              min={12}
              max={32}
              valueLabelDisplay="off"
              style={{ marginRight: "40px", flex: 1, minWidth: "200px" }}
            />
          </FlexWrap>
          <FlexWrap>
            <label>Color</label>
            <div style={{ marginTop: "-10px", width: "85px" }}>
              <ColorPicker
                value={postcardBack.font_color}
                onChange={color => handleChange("font_color", color)}
              />
            </div>
          </FlexWrap>
        </FormLineWrapper>
      </FormWrapper>
      <PostcardBackMock
        text={postcardBack.postcard_text}
        valediction={postcardBack.postcard_valediction}
        font_name={postcardBack.font_name}
        font_color={postcardBack.font_color}
        font_size={postcardBack.font_size}
      />
    </Wrapper>
  );
}
export default PostcardBack;
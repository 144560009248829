import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getThumbnail } from "../../data/util";

import Divider from '@material-ui/core/Divider';

const Row = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  text-transform: capitalize;
  font-size: 16px;
  .full {
    flex: 1;
    margin-left: 10px;
  }
  > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .black-text { color: #000; }
  .small-text { font-size: 14px; }
`;
const Image = styled.div `
  background-image: url(${props => props.img});
  width: 62px;
  height: 62px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
`;

function CartSmall({ items, shippingOption }) {

  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    const photos = [...items].map(i => i.orderAttrs.postcardList[0].thumbnail);

    Promise.all(photos.map(getThumbnail)).then(results => {
      setImageData(results)
    })
  }, [items]);

  let total = 0;
  if (items.length) {
    total = items.map(i => i.quantity * i.price)
      .reduce((a, b) => a + Number(b))
  }

  return (
    <div>
      {items.map((d,i) => {
        return (
          <Row key={`cart${i}`}>
            <span>
              <Image img={imageData[i]} />
            </span>
            <span className="full">
              <span className="black-text">
                {`${d.orderAttrs.postcardList.length} Postcard Design${d.orderAttrs.postcardList.length > 1 ? "s" : ""}`}
              </span>
              <span style={{ marginTop: "5px" }}>
                {`${d.orderAttrs.addressList.length} Recipient${d.orderAttrs.addressList.length > 1 ? "s" : ""}`}
              </span>
            </span>
          </Row>
        );
      })}
      <Divider style={{ margin: "20px 0" }}/>
        <Row>
          <span>Total</span>
          <span className="black-text">
            {total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </span>
        </Row>
    </div>
  );
}
export default CartSmall;
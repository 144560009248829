import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { useSelector } from "react-redux";
import usePageTracking from "../usePageTracking";

import BannerHamburger from './BannerHamburger';
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const styles = theme => ({
  buttons: {
    display: "flex",
    flex: 1
  },
  storeName: {
    color: theme.palette.secondary.contrastText,
  },
  menuButton: {
    marginRight: "30px",
    textDecoration: "none",
    color: theme.palette.secondary.contrastText
  },
  appbar: {
    flexGrow: 1,
    padding: "0 60px",
    [theme.breakpoints.down('md')]: {
      padding: "0 10px"
    },
    position: "absolute",
    width: "100%",
    top: 0
  }
});

const H3 = styled.h3`
  font-size: 1.5em;
  line-height: 1.2em;
  margin: 0;
`;

function Banner({ classes, quantity, config, width }) {
  const isAdmin = useSelector(state => state.reducers.isAdmin);

  usePageTracking();

  const number = quantity ? ` (${quantity})` : "";

  let links = [{ url: "/product/postcard", label: "Get Started" }];
  if (config.about_page) links.push({ url: "/about", label: "About" })

  if (isAdmin) {
    links.push({ url: "/config", label: "Config" })
    links.push({ url: "/orders", label: "Orders" })
    links.push({ url: "/admin-postcards", label: `"Free" Postcards` })
  }

  const renderLinks = (link, i) => (
    <Link to={link.url} key={`l${i}`}
      style={{flex: i === links.length - 1 ? 1 : null }}
      className={classes.menuButton}
    >
      <Typography variant="button" gutterBottom>{link.label}</Typography>
    </Link>
  )

  let menu;
  if (isWidthDown('sm', width)) {
    menu = <BannerHamburger links={links.map(renderLinks)} number={number} />
  } else {
    menu = (<span className={classes.buttons}>
        {links.map(renderLinks)}
        <Link to={`/cart`} className={classes.menuButton} style={{ marginRight: 0 }}>
          <Typography variant="button" gutterBottom>Cart{number}</Typography>
        </Link>
      </span>)
  }

  return (
    <AppBar position="static" color="secondary" className={classes.appbar}>
      <Toolbar>
        <Link to={`/`} className={classes.menuButton}>
          <div className="logo" />
          <H3 className={classes.storeName}>{config.store_name}</H3>
        </Link>
        { menu }
      </Toolbar>
    </AppBar>
  );
};
export default withWidth()(withStyles(styles)(Banner));
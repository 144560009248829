import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div `
  position: relative;
  width: 100%;
  height: 120px;
  line-height: 100px;
  @media (max-width: 960px) {
    height: 100px;
    line-height: 80px;
  }
  @media (max-width: 600px) {
    height: 100px;
    line-height: 80px;
  }
`;
const Text = styled.div`
  position: absolute;
  font-size: 72px;
  font-family: Sacramento;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 960px) {
    font-size: 64px;
  }
  @media (max-width: 600px) {
    font-size: 48px;
  }
`;

const HeaderTextEffect = (props) => {

  let offset = 2;  
  
  const colors = [
    {color: "#FFFF37", top: -offset, left: -offset},
    {color: "cyan", top: offset, left: offset},
    {color: "magenta", top: -offset, left: offset},
    {color: "black", top: 0, left: 0}
  ];
  return (
    <Wrapper>
      { colors.map((c,i) => (
        <Text key={`text${i}`}
          style={{
            color: c.color,
            left: `${c.left}px`,
            top: `${c.top}px`,
          }}
        >
          {props.children}
        </Text>
      ))}
    </Wrapper>
  );
};

export default HeaderTextEffect;
import React, { useState } from 'react';
import styled from "styled-components";

import ShippingForm from "./ShippingForm";

import { IconButton } from '@material-ui/core';
import { Edit, DeleteOutline } from '@material-ui/icons';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ListWrapper = styled.div`
  font-size: 14px;
  margin-left: 40px;
  flex: 1;
  max-height: 600px;
  overflow-y: scroll;
  @media (max-width: 650px) {
    margin: 20px 0 0 0;
  }
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  margin-bottom: 20px;
`;
const Badge = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  font-size: 18px;
  line-height: 18px;
  @media (max-width: 650px) {
    margin-right: 10px;
    width: 32px;
    height: 32px;
  }
`;

function Shipping({ addressList, setAddressList }) {
  const [editAddress, setEditAddress] = useState({});

  const setAddress = address => {
    const newList = [...addressList];
    newList.push(address);
    setAddressList(newList);
    setEditAddress({});
  }

  const updateAddress = (address, index) => {
    setEditAddress(address);
    deleteAddress(index);
  }

  const deleteAddress = index => {
    const newList = [...addressList];
    newList.splice(index, 1);
    setAddressList(newList);
  }

  return (
    <Wrapper>
      <div>
        <ShippingForm address={editAddress} setAddress={setAddress} />
      </div>

      <ListWrapper>
        {addressList.map((a,i) => (
          <ListItem key={`add${i}`}>
            <Badge>{i+1}</Badge>

            <div style={{ flex: 1 }}>
              <div>{a.name}</div>
              <div>{a.address_line1}</div>
              <div>{a.address_line2}</div>
              <div>{`${a.address_city}, ${a.address_state}, ${a.address_zip}`}</div>
            </div>

            <IconButton
              color="secondary" aria-label="Edit"
              onClick={() => updateAddress(a, i)}
              style={{ background: "#888", marginRight: "10px"}}
            >
              <Edit />
            </IconButton>
            <IconButton
              color="secondary" aria-label="Edit"
              onClick={() => deleteAddress(i)}
              style={{ background: "#888"}}
            >
              <DeleteOutline />
            </IconButton>
          </ListItem>
        ))}
      </ListWrapper>
    </Wrapper>
  );
}
export default Shipping;
import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { Button } from '@material-ui/core';

import HeaderTextEffect from "../ui/HeaderTextEffect";

const Image = styled.div`
  width: 100%;
  height: 300px;
  background: url(${process.env.PUBLIC_URL}/table.jpg);
  background-size: cover;
  background-position: 50%;
  margin: 40px 0;
  @media (max-width: 700px) {
    height: 200px;
  }
  @media (max-width: 500px) {
    height: 150px;
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  border-top: 3px dotted #333;
  margin-top: 40px;
  padding-top: 40px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
const Column = styled.div`
  width: 50%;
  padding: 0 40px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 20px;
    font-size: 14px;
  }
`;
const Question = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  b {
    background: rgba(0,255,255,.1);
    margin-bottom: 5px;
    display: inline-block;
  }
`;
const Contact = styled.div`
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
  font-size: 36px;
  > span { text-decoration: underline; text-decoration-color: #f0f; }
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const ProjectInspo = () => {
  return (
    <div>
      <Flex>
        <Column>
          <HeaderTextEffect>Information</HeaderTextEffect>
          <Question>
            <b>How much do these cost?</b>
            <div>Each postcard costs $1.40. No add-ons, no upsells.</div>
          </Question>
          <Question>
            <b>How long do they take to be delivered?</b>
            <div>Postcards are typically delivered about one week after the scheduled order date.</div>
          </Question>
          <Question>
            <b>Where can I send them to?</b>
            <div>Anywhere in the United States, thanks to the USPS!</div>
            <div>Apologies to our international customers... We have yet to find a well-priced global postcard printing company.</div>
          </Question>
        </Column>
        <Column>
          <HeaderTextEffect>Inspiration</HeaderTextEffect>
          <div style={{ marginBottom: "10px" }}>Why would you want to schedule postcards?</div>
          <div>Well, the reason I built this site is that my grandmother doesn't have the internet! If I want to share updates about my life, I need to print and mail photos for her. I thought it would be more fun for her to receive a postcard every few days, rather than all of them at once. And thus was born the idea for a postcard batch scheduler!</div>
        </Column>
      </Flex>

      <Link to="/product/postcard" style={{ textDecoration: "none", textAlign: "center", display: "block" }}>
        <Button variant="contained" color="secondary">
          Get Started on your Postcards
        </Button>
      </Link>

      <Image />

      <Contact>Questions? Contact us at <span>hi@postcardgifts.com</span></Contact>
    </div>
  );
};

export default ProjectInspo;
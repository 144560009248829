import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Loading from "../ui/Loading";

import { Button, Paper } from '@material-ui/core';

const Flex = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  margin-top: 20px;

  @media (max-width: 800px) {
    font-size: 28px;
  }

  @media (max-width: 650px) {
    font-size: 22px;
    flex-direction: column;
    > div { margin-bottom: 20px !important; }
  }
`;
const FlexRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: start;
`;
const NumberDiv = styled.div`
  display: inline-block;
  font-weight: bold;
  background: #eee;
  padding: 2px 10px;
`;

const ProductDetails = props => {
  const { numPostcards, numAddresses, price, addToCart } = props;
  const [totalPrice, setTotalPrice] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTotalPrice(+numPostcards * +numAddresses * +price);
  }, [numPostcards, numAddresses, price])

  const displayPrice = num => (
    Number(num).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  )

  const clickAddToCart = () => {
    setLoading(true);
    addToCart();
  }

  return (
    <Paper style={{ padding: "20px" }}>
      <Flex>
        <div>
          <FlexRow style={{ marginBottom: "10px" }}>
            <NumberDiv>{numPostcards}</NumberDiv>
            <div style={{ margin: "0 10px" }}>
              {`postcard design${numPostcards === 1 ? "" : "s"}`}
            </div>
          </FlexRow>
          <FlexRow>
            <div style={{ margin: "0 10px 0 0" }}>sent to</div>
            <NumberDiv>{numAddresses}</NumberDiv>
            <div style={{ margin: "0 10px" }}>
              {`recipient${numAddresses === 1 ? "" : "s"}`}
            </div>
          </FlexRow>
        </div>
        <div style={{ margin: "0 30px" }}>{" × "}</div>
        <div>
          <div style={{ fontWeight: "600", textAlign: "right" }}>
            {displayPrice(price)}
          </div>
          <div style={{ fontSize: "18px", textAlign: "center" }}>each</div>
        </div>
        <div style={{ margin: "0 30px" }}>{" = "}</div>
        <div>
          <div style={{ fontWeight: "600", textAlign: "right" }}>
            {displayPrice(totalPrice)}
          </div>
          <div style={{ fontSize: "18px", textAlign: "center" }}>total</div>
        </div>
      </Flex>
      
      <div style={{ textAlign: "center", margin: "40px 0 20px" }}>
        <Button
          variant="contained" color="secondary"
          disabled={!numAddresses || !numPostcards || loading}
          onClick={clickAddToCart}
        >
          Add To Cart
        </Button>
        { loading && <Loading margin={"0 0 20px 0"} /> }
      </div>
    </Paper>
  );
};
export default ProductDetails;
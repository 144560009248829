import React from 'react';
import { Link, withRouter } from "react-router-dom";

import PageWrapper from '../ui/PageWrapper';
import Paper from '@material-ui/core/Paper';

const Confirm = ({ location }) => (
  <PageWrapper>
    <Paper style={{ padding: "40px", minHeight: "500px" }}>
      <h2 style={{ marginTop: 0, fontWeight: 600 }}>Thank you for your purchase!</h2>
      <p>A confirmation email has been sent to <b>{location.state.order.email}</b>.</p>
      <p>Order ID: {location.state.order.id}</p>
      <Link to={`/order/${location.state.order.id}`}
        style={{
          color: "black",
          background: "rgba(255,0,255,.1)",
          padding: "2px 5px",
          textDecoration: "none",
          marginLeft: "-2px"
        }}
      >
        Follow your postcards' progress here
      </Link>
    </Paper>
  </PageWrapper>
);
export default withRouter(Confirm);
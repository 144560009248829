import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import DateFnsUtils from '@date-io/date-fns';
import { getThumbnail } from "../../../data/util";

import { DeleteOutline } from '@material-ui/icons';

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 20px 0 0px;
  > div {
    margin: 0 20px 20px 0;
  }
`;
const IMG = styled.img`
  max-width: 150px;
  max-height: 150px;
  border: 1px solid #ccc;
`;
const Delete = styled.div`
  background: rgba(0,0,0,.5);
  padding: 10px;
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  display: none;
  cursor: pointer;
  &:hover { color: #ff0; }
  svg {
    font-size: 1.75rem;
  }
`;
const Wrapper = styled.div`
  position: relative;
  &:hover {
    ${Delete} {
      display: block;
    }
  }
`;

function PostcardList({ postcardList, setPostcardList }) {
  const [imageData, setImageData] = useState([]);
  const dateFns = new DateFnsUtils();

  useEffect(() => {
    postcardList.forEach(p => {
      const img = imageData.find(i => i.key === p.thumbnail);
      if (img) return;

      getThumbnail(p.thumbnail).then(img => {
        setNewImageData(p.thumbnail, img);
      });
    });
  }, [postcardList])

  const setNewImageData = (key, value) => {
    const newData = [...imageData];
    const index = newData.findIndex(n => n.key === key);
    if (index === -1) {
      newData.push({
        key,
        value
      })
    } else newData[index].value = value;

    setImageData(newData);
  }

  const removePostcard = index => {
    const newList = [...postcardList];
    newList.splice(index, 1);
    setPostcardList(newList);
  }

  return (
    <div>
      {!!!postcardList.length && (
        <p style={{ fontStyle: "italic" }}>No saved designs yet</p>
      )}
      <ListWrapper>
        {postcardList.map((p,i) => {
          const img = imageData.find(i => i.key === p.thumbnail);
          return (
            <div key={`post${i}`}>
              <Wrapper>
                { img && <IMG src={img.value} />}
                <Delete onClick={() => removePostcard(i)}>
                  <DeleteOutline />
                </Delete>
              </Wrapper>
              <div style={{ fontSize: "14px", textAlign: "center" }}>
                {p.date && dateFns.format(p.date, 'MM/dd/yyyy')}
              </div>
            </div>
          );
        })}
      </ListWrapper>
    </div>
  );
}
export default PostcardList;
import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import { TextField, Button } from '@material-ui/core';

const Wrapper = styled.div`
  background: #eee;
  padding: 0 20px 20px;
  max-width: 500px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  @media (max-width: 650px) {
    button { margin-top: 20px; }
  }
`;

function ShippingForm(props) {
  const blankAddress = {
    name: "",
    address_line1: "",
    address_line2: null,
    address_city: "",
    address_state: "",
    address_zip: ""
  }
  const [address, setAddress] = useState(blankAddress);

  useEffect(() => {
    if (Object.keys(props.address).length)
      setAddress(props.address);
  }, [props.address])

  const handleChange = (name, value) => {
    const newAddress = Object.assign({ ...address }, {
      [name]: value
    });
    setAddress(newAddress);
  }

  const submitAddress = () => {
    props.setAddress(address);
    setAddress(blankAddress)
  }

  return (
    <Wrapper>
      <TextField
        autoComplete="name"
        label="Name"
        value={address.name}
        onChange={(e) => handleChange('name', e.target.value.slice(0,40))}
        margin="dense"
        fullWidth
      />

      <TextField
        autoComplete="shipping address-line1"
        label="Street Address"
        value={address.address_line1}
        onChange={(e) => handleChange('address_line1', e.target.value.slice(0,64))}
        margin="dense"
        fullWidth
      />
      <TextField
        autoComplete="shipping address-line2"
        label="Apt, suite, etc (optional)"
        value={address.address_line2 || ""}
        onChange={(e) => handleChange('address_line2', e.target.value.slice(0,64))}
        margin="dense"
        fullWidth
      />
      <TextField
        autoComplete="shipping address-level2"
        label="City"
        value={address.address_city}
        onChange={(e) => handleChange('address_city', e.target.value)}
        margin="dense"
        style={{ marginRight: "40px" }}
      />
      <TextField
        autoComplete="shipping address-level1"
        label="State"
        value={address.address_state}
        onChange={(e) => handleChange('address_state', e.target.value)}
        margin="dense"
      />
      <Flex>
        <TextField
          autoComplete="shipping postal-code"
          label="Zip"
          value={address.address_zip}
          onChange={(e) => handleChange('address_zip', e.target.value)}
          margin="dense"
        />
        <Button variant="contained" color="primary"
          disabled={!address.address_zip.length}
          onClick={submitAddress}
        >
          Save Address
        </Button>
      </Flex>
    </Wrapper>
  );
}
export default ShippingForm;
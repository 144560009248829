import React, { useState, useRef } from 'react';
import styled from "styled-components";

import PostcardFront from "./PostcardFront";
import PostcardBack from "./PostcardBack";
import Loading from "../../ui/Loading";
import UINote from "../../ui/UINote";

import { Button, Divider } from '@material-ui/core';

const Canvas = styled.canvas`
  position: absolute;
  left: -10000px;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  flex: 1;
`;

function PostcardForm(props) {
  const sizes = {
    "portrait": {
      width: 1275,
      height: 1875
    },
    "landscape": {
      width: 1875,
      height: 1275
    }
  }

  const blankPostcard = {
    postcard_text: "",
    postcard_valediction: "",
    font_name: "Patrick Hand",
    font_color: "#000000",
    font_size: 24
  }

  const [postcardBack, setPostcardBack] = useState(blankPostcard);
  const [orientation, setOrientation] = useState("portrait");
  const [currentIMG, setCurrentIMG] = useState();
  const [buttonText, setButtonText] = useState("Save Design");
  const [loading, setLoading] = useState(false);

  const size = sizes[orientation];
  const canvas = useRef();

  const savePostcard = () => {
    if (!currentIMG) return;

    setLoading(true);

    const widthToHeight = size.width/size.height;

    const ctx = canvas.current.getContext("2d"); // Creates a contect object
    const ratio = currentIMG.width/currentIMG.height;

    if (ratio > widthToHeight) {
      // wider than the postcard
      const drawWidth = ratio*size.height;
      ctx.drawImage(currentIMG, (size.width - drawWidth)/2, 0, drawWidth, size.height);
    } else {
      const drawHeight = (1/ratio)*size.width;
      ctx.drawImage(currentIMG, 0, (size.height - drawHeight)/2, size.width, drawHeight);
    }

    const imgData = canvas.current.toDataURL("image/png");
    fetch("/save-postcard", {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify({
          imgData,
          orientation,
          postcardBack
        })
      }).then((response) => response.json())
      .then((json) => {

        props.addPostcard({
          thumbnail: json.thumbURL,
          orderId: json.orderId
        })

        setCurrentIMG(null);
        setPostcardBack(blankPostcard);
        setButtonText("Saved!")
        setLoading(false);

        setTimeout(() => {
          setButtonText("Save Design");
        }, 5000);
      })
  }

  return (
    <div>
      <Divider style={{ margin: "20px 0" }}/>

      <PostcardFront
        orientation={orientation}
        setOrientation={setOrientation}
        size={size}
        currentIMG={currentIMG}
        setCurrentIMG={setCurrentIMG}
      />

      <Divider style={{ margin: "20px 0" }}/>

      <PostcardBack
        postcardBack={postcardBack}
        setPostcardBack={setPostcardBack}
      />
      <Divider style={{ margin: "20px 0" }}/>

      <Flex>
        <ButtonWrapper>
          <Button onClick={savePostcard} variant="contained" color="primary">
            {buttonText}
          </Button>
          {loading && <Loading />}
        </ButtonWrapper>
        <UINote>When you're finished with all of your designs, scroll down to finish ordering!</UINote>
      </Flex>

      <Canvas ref={canvas} width={size.width} height={size.height} />
    </div>
  );
}
export default PostcardForm;
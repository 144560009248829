import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import PageWrapper from './ui/PageWrapper';
import TextEffect from "./ui/TextEffect";
import PageIntro from "./landing/PageIntro";
import ProjectInspo from "./landing/ProjectInspo";

import { Paper, Button } from '@material-ui/core';

const Wrapper = styled(Paper)`
  padding: 40px;
  @media (max-width: 690px) {
    padding: 20px;
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 690px) {
    flex-wrap: wrap;
  }
`;
const LeftSide = styled.div`
  flex: 1;
  min-width: 300px;
`;
const Image = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/hero.jpg);
  width: 400px;
  height: 600px;
  background-size: cover;
  background-position: 50%;
  margin-top: -80px;
  @media (max-width: 960px) {
    width: 300px;
    height: 455px;
    margin-top: -20px;
  }
  @media (max-width: 680px) {
    margin-top: 20px;
  }
`;
const Note = styled.div`
  text-align: center;
  font-size: 12px;
  margin: 40px 0 20px;
  a {
    color: black;
    text-decoration: none;
    background-color: #FFFFBB;
  }
`;

const Landing = ({ config }) => {
  return (
    <PageWrapper>
      <Wrapper>
        <TextEffect>postcards</TextEffect>

        <Flex>
          <LeftSide>
            <PageIntro/>
            <Link to="/product/postcard" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="secondary">
                Let's go, I'm sold already
              </Button>
            </Link>
          </LeftSide>
          <Image />
        </Flex>

        <ProjectInspo />
      </Wrapper>

      <Note>made by <a href="http://rachelbinx.com">rachel binx</a>, for the love of mail</Note>
    </PageWrapper>
  );
};

export default Landing;
import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import UINote from "../../ui/UINote";

import { Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Error } from '@material-ui/icons';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  .MuiFormGroup-row {
    margin-top: 40px;
  }
  @media (max-width: 650px) {
    flex-direction: column-reverse;
    .MuiFormGroup-row {
      margin-top: 20px;
    }
  }
`;
const CanvasWrapper = styled.div`
  position: relative;
  margin-right: 40px;
  flex-shrink: 0;
`;
const FakeCanvas = styled.div`
  border: 1px solid black;
  background-position: 50%;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin: -1px;
  box-shadow: 3px 3px 5px #ccc;
`;
const CanvasBorder = styled.div`
  display: inline-block;
  border: 10px solid rgba(255,255,255,.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ErrorText = styled.div`
  color: #f00;
  font-size: 14px;
  display: flex;
  align-items: center;
  > svg { margin-right: 5px; }
`;

function PostcardFront({ orientation, setOrientation, size, currentIMG, setCurrentIMG, width }) {
  const [error, setError] = useState(false);
  const fakeCanvas = useRef();

  useEffect(() => {
    if (!currentIMG)
      fakeCanvas.current.style.backgroundImage = null;
    else
      fakeCanvas.current.style.backgroundImage = `url(${currentIMG.src})`;

    if (!currentIMG) return;

    if (currentIMG.width < size.width || currentIMG.height < size.height)
      setError(true);
    else
      setError(false);
  }, [currentIMG]);

  const uploadImage = e => {
    if (!e.target.files) return;

    // forgive me

    let imageFile = e.target.files[0]; //here we get the image file
    if (!imageFile) return;

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onloadend = function(e) {
      const image = new Image();
      image.src = e.target.result;
      image.onload = function(ev) {
        setCurrentIMG(image)
      }
    }
  };

  const scale = isWidthDown('sm', width) ? 5.5 : 4;

  return (
    <Wrapper>
      <CanvasWrapper style={{ width: size.width/scale, height: size.height/scale }}>
        <FakeCanvas ref={fakeCanvas} />
        <CanvasBorder />
      </CanvasWrapper>
      <div>
        <Button component="label" variant="contained" color="secondary">
          <input
            accept="image/*"
            type="file"
            onChange={uploadImage}
            style={{ display: "none" }}
          />
          Upload Image
        </Button>

        <RadioGroup row
          aria-label="font_name" name="font_name" 
          value={orientation}
          onChange={(e) => setOrientation(e.target.value)}
        >
          <FormControlLabel
            value="portrait" control={<Radio size="small" />}
            label={"Portrait"}
          />
          <FormControlLabel
            value="landscape" control={<Radio size="small" />}
            label={"Landscape"}
          />
        </RadioGroup>

        <UINote>Image should be at least 1275px by 1875px</UINote>
        <div style={{ marginTop: "-1em" }}>
          <UINote>Mockup includes 1/8" safe zone on the image</UINote>
        </div>

        {error && (
          <ErrorText><Error /> Image is low-resolution, and will appear pixelated when printed</ErrorText>
        )}
      </div>
    </Wrapper>
  );
}
export default withWidth()(PostcardFront);
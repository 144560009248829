import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import PostcardForm from "./postcard/PostcardForm";
import Scheduler from "./Scheduler";
import Shipping from "./Shipping";
import ShippingBulkUpload from "./ShippingBulkUpload";
import ProductDetails from "./ProductDetails";

import { Paper } from '@material-ui/core';

const H2 = styled.h2`
  margin-top: 0;
  @media (max-width: 650px) {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }
`;
const ShippingH2 = styled(H2)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    > div { display: none; }
  }
`;

function PostcardCreation({ price, setQuantity, addToCart }) { 
  const [addressList, setAddressList] = useState([]);
  const [postcardList, setPostcardList] = useState([]);

  useEffect(() => {
    setQuantity(addressList.length * postcardList.length)
  }, [addressList.length, postcardList.length]);

  const callPostcardAPI = (addressList, postcard) => {
    const postBody = {
      orderId: postcard.orderId,
      printDate: postcard.date,
      addressList: addressList,
    }
    return new Promise(function(resolve, reject) {
      fetch("/save-order", {
          method: 'POST',
          headers: new Headers({ 'content-type': 'application/json' }),
          body: JSON.stringify(postBody)
        }).then((response) => response.json())
        .then(result => {
          resolve(result)
        });
    });
  }

  const addPostcard = postcard => {
    const newList = [...postcardList];
    newList.push(postcard);
    setPostcardList(newList);
  }

  const prepareOrder = () => {
    const createRows = postcardList.map(p => callPostcardAPI(addressList, p));
    Promise.all(createRows).then(results => {
      const idList = [...results.map(item => item.map(r => r.id))].flat();
      const uuidList = [...results.map(item => item.map(r => r.orderId))].flat();

      addToCart({
        addressList,
        postcardList,
        idList,
        uuidList
      })
    })
  }

  return (
    <div>
      <Paper style={{ padding: "20px", marginBottom: "40px" }}>
        <H2 style={{ marginTop: 0 }}>Create a Postcard Design</H2>
        <PostcardForm addPostcard={addPostcard} />
      </Paper>

      <Paper style={{ padding: "20px", marginBottom: "40px" }}>
        <H2 style={{ margin: 0 }}>Postcard Schedule</H2>
        <Scheduler
          postcardList={postcardList}
          setPostcardList={setPostcardList}
        />
      </Paper>

      <Paper style={{ padding: "20px", marginBottom: "40px" }}>
        <ShippingH2>
          Postcard Recipients
          <ShippingBulkUpload
            addressList={addressList}
            setAddressList={setAddressList}
          />
        </ShippingH2>
        <Shipping
          addressList={addressList}
          setAddressList={setAddressList}
        />
      </Paper>

      <ProductDetails
        numPostcards={postcardList.length}
        numAddresses={addressList.length}
        price={price}
        addToCart={prepareOrder}
      />
    </div>
  );
}
export default PostcardCreation;
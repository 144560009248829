import React, { useState } from 'react';
import styled from "styled-components";
import { CSVLink } from "react-csv";

import {
  Button,
  Dialog,
  DialogContent,
  IconButton
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const HeaderText = styled.h3 `
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ActionWrapper = styled.div`
  border-top: 1px solid #ccc;
  margin-top: 40px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
`;
const ErrorText = styled.div`
  color: #f00;
  font-size: 14px;
  padding-bottom: 20px;
`;

function ShippingBulkUpload({ addressList, setAddressList }) { 
  const [showModal, setShowModal] = useState(false);
  const [columnError, setColumnError] = useState(false);
  const [addressError, setAddressError] = useState([]);

  const uploadAddresses = e => {
    if (!e.target.files[0]) return;
    setColumnError(false);
    setAddressError([]);

    const data = new FormData();
    data.append('file', e.target.files[0]);

    fetch("/upload-addresses", {
        method: 'POST',
        body: data
      }).then((response) => response.json())
      .then(addresses => {
        const sample = addresses[0];
        const columns = Object.keys(sample).sort((a,b) => {
          if (a > b) return 1;
          else return -1;
        });

        const needsToBe = `["address_city","address_line1","address_line2","address_state","address_zip","name"]`;

        if (JSON.stringify(columns) === needsToBe) {
          verifyAddresses(addresses);
        } else {
          setColumnError(true);
        }
      });
  }

  const verifyAddresses = addresses => {
    let errorAddresses = [];

    addresses.forEach(a => {
      if (a.name.length > 40) {
        errorAddresses.push({
          address: a,
          error: "Name must be 40 characters or less"
        })
      } else if (a.address_line1.length > 64 || a.address_line2.length > 64) {
        errorAddresses.push({
          address: a,
          error: "Address line must be 64 characters or less"
        })
      } else if (a.address_zip.length !== 5) {
        errorAddresses.push({
          address: a,
          error: "Zip code must be 5 digits"
        })
      }
    })

    if (errorAddresses.length !== 0) {
      setAddressError(errorAddresses);
    } else {
      setAddressList(addresses);
      setShowModal(false);
    }
  }

  const testCSV = [{
    name: "Postcard Recipient",
    address_line1: "123 Anywhere St.",
    address_line2: "Apt 2",
    address_city: "Anytown",
    address_state: "CA",
    address_zip: "00000"
  }]

  return (
    <div>
      <Button variant="outlined"
        onClick={() => setShowModal(true)}
      >
        Upload a List of Addresses
      </Button>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <DialogContent>
          <HeaderText>
            Bulk Address Upload
            <IconButton aria-label="close" onClick={() => setShowModal(false)}>
              <CloseIcon />
            </IconButton>
          </HeaderText>

          <div style={{ marginBottom: "10px" }}>Postcard Gifts supports bulk upload for shipping addresses!</div>
          <div>Please format your address list as a CSV. Download the sample CSV below for the <b>required</b> column names.</div>

          {!!addressError.length && (
            <div style={{ marginTop: "20px"}}>
              <ErrorText>Unfortunately some of your addresses have errors, please correct and re-upload:</ErrorText>
              {addressError.map((a,i) => (
                <div key={`err${i}`}>
                  <div>{Object.keys(a.address).map(b => a.address[b]).join(", ")}</div>
                  <ErrorText>{`Reason: ${a.error}`}</ErrorText>
                </div>
              ))}
            </div>
          )}

          <ActionWrapper>
            <CSVLink
              headers={["name", "address_line1", "address_line2", "address_city", "address_state", "address_zip"]}
              data={testCSV}
              filename={`sample_addresses.csv`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained">Sample CSV</Button>
            </CSVLink>

            <Button component="label" variant="contained" color="secondary">
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={uploadAddresses}
                style={{ display: "none" }}
              />
              Upload Addresses
            </Button>
          </ActionWrapper>

          {columnError && <ErrorText>Sorry, but your CSV column names do not follow the proper format. <br/> Please check your column names and try again.</ErrorText>}

        </DialogContent>
      </Dialog>
    </div>
  );
}
export default ShippingBulkUpload;
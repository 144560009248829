import React from 'react';
import styled from 'styled-components';

const List = styled.div`
  margin: 20px 0;

  > div {
    margin: 0 40px 20px 0;
    padding: 10px;
    font-size: 16px;
  }
  @media (max-width: 650px) {
    > div {
      margin: 0 0 20px 0;
      font-size: 14px;
    }
  }
`;
const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  @media (max-width: 960px) {
    font-size: 18px !important;
  }
  @media (max-width: 960px) {
    font-size: 16px;
  }
`;
const Magenta = styled.div`
  border-top: 3px solid magenta;
  border-left: 3px solid magenta;
  background: rgba(255,0,255,.1);
`;
const Cyan = styled.div`
  border-top: 3px solid cyan;
  border-left: 3px solid cyan;
  background: rgba(0,255,255,.1);
`;

const PageIntro = () => {
  return (
    <List>
      <Magenta>
        <Title>Create and send a postcard for $1.40</Title>
      </Magenta>
      <Cyan>
        <Title>Upload your photos for the postcard designs</Title>
        <div style={{ marginTop: "10px" }}>
          Write a personalized note on the back, too!
        </div>
      </Cyan>
      <Magenta>
        <Title>Send your cards to multiple addresses</Title>
        <div style={{ marginTop: "10px" }}>
          Share photos with your friends and family!
        </div>
      </Magenta>
      <Cyan>
        <Title>Schedule how often to send your postcards</Title>
        <div style={{ marginTop: "10px" }}>
          What's the fun in sending everything at once?
        </div>
        <div>
          You can set up your postcards to ship every few days, weeks, or months!
        </div>
      </Cyan>
    </List>
  );
};

export default PageIntro;
import React from 'react';
import styled from 'styled-components';
import withWidth from '@material-ui/core/withWidth';

const Wrapper = styled.div `
  position: relative;
  width: 100%;
  height: 340px;
  line-height: 240px;
  @media (max-width: 960px) {
    height: 200px;
    line-height: 140px;
  }
  @media (max-width: 600px) {
    height: 120px;
    line-height: 80px;
  }
`;
const Underline = styled.div`
  border-bottom: 3px dotted #333;
  position: absolute;
  top: 210px;
  width: 100%;

  @media (max-width: 960px) {
    top: 120px;
  }
  @media (max-width: 600px) {
    border-bottom: 2px dotted #333;
    top: 75px;
  }
`;
const Text = styled.div`
  position: absolute;
  font-size: 288px;
  font-family: Sacramento;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 960px) {
    font-size: 150px;
  }
  @media (max-width: 600px) {
    height: 200px;
    font-size: 100px;
    margin-bottom: 40px;
  }
`;

const TextEffect = (props) => {

  let offset = 5;
  if (props.width === "xs") offset = 2;
  else if (props.width === "sm") offset = 3;
  
  
  const colors = [
    {color: "#FFFF37", top: -offset, left: -offset},
    {color: "cyan", top: offset, left: offset},
    {color: "magenta", top: -offset, left: offset},
    {color: "white", top: 0, left: 0}
  ];
  return (
    <Wrapper>
      <Underline />
      { colors.map((c,i) => (
        <Text key={`text${i}`}
          style={{
            color: c.color,
            left: `${c.left}px`,
            top: `${c.top}px`,
          }}
        >
          {props.children}
        </Text>
      ))}
    </Wrapper>
  );
};

export default withWidth()(TextEffect);
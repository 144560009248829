import React from 'react';
import styled from 'styled-components';

import { Button, Tabs, Tab } from "@material-ui/core";

const Table = styled.table `
  padding: 20px;
  width: 100%;
  > tbody > tr > td {
    padding: 10px 5px;
  }
`;

const OrderTable = (props) => {
  const { view_status, orders } = props;

  return (
    <div>
      <Tabs value={view_status} onChange={(e, v) => props.switchView(v)}>
        <Tab value="paid" label="paid" />
        <Tab value="fulfilled" label="fulfilled" />
        <Tab value="created" label="created" />
        <Tab value="canceled" label="canceled" />
      </Tabs>
      <Table>
        <tbody>
          { orders.map((order,i) => {
            const time = new Date(order.created*1000);
            let metadata = [];
            for (let key in order.metadata) {
              if (key.indexOf("address") !== -1) {
                let m = JSON.parse(order.metadata[key])
                for (let mKey in m) {
                  if (mKey === "name")
                    metadata.push(`${mKey}: ${m[mKey]}`);
                }
              }
            }
            return (
              <tr key={`order${i}`}>
                {/*<td>{getStatus(order.metadata.status)}</td>*/}
                <td>
                  <div>{`${time.getMonth()}/${time.getDate()}/${time.getFullYear()}`}</div>
                </td>
                <td style={{ textAlign: "right" }}>
                  {(order.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
                <td>
                  {order.items.map((o,i) => {
                    if (i < order.items.length - 2)
                      return <div key={`item${i}`}>{o.quantity} {o.description}</div>;
                    else return(null);
                  })}
                </td>
                <td style={{ fontSize: "14px" }}>
                  {metadata.map((m,i) => <div key={`m${i}`}>{m}</div>)}
                </td>
                <td style={{ fontSize: "14px" }}>
                  <div>{order.email}</div>
                </td>
                <td>
                  <a href={`http://postcardgifts.com/order/${order.id}`} style={{ textDecoration: "none" }}>
                    <Button variant="contained" color="secondary">View Order</Button>
                  </a>
                </td>
                {/*<td>
                  <select value={statuses[i]} 
                    onChange={(e) => props.updateStatus(i, e.target.value)}
                  >
                    <option>Ordered</option>
                    <option>Processed</option>
                    <option>Received</option>
                    <option>Shipped</option>
                  </select>
                </td>
                <td>
                  <Button onClick={() => props.updateOrder(i)}
                    variant="contained" color="secondary"
                  >
                    Notify
                  </Button>
                </td>
              */}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default OrderTable;
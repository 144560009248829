import React from 'react';
import styled from "styled-components";

const Wrapper = styled.p`
  font-size: 14px;
  background: rgb(255,255,55, .1);
  padding: 2px;
  display: inline-block;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;

const UINote = (props) => (
  <Wrapper>
    <span role="img" aria-label="checkmark" style={{ marginRight: "5px" }}>☑️</span>
    {props.children}
  </Wrapper>
);
export default UINote;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { getThumbnail } from "../../data/util";

const Table = styled.table `
  width: 100%;
  border-collapse: collapse;

  thead > tr > th {
    font-weight: normal;
    font-size: 12px;
    color: #888;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  tbody > tr > td {
    padding: 10px 4px;
    border-bottom: 1px solid #ddd;
  }
`;
const Flex = styled.div `
  display: flex;
  align-items: center;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: start;
  }
`;
const Image = styled.div `
  background-image: url(${props => props.img});
  width: 125px;
  height: 125px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  @media (max-width: 650px) {
    width: 62px;
    height: 62px;
  }
`;
const AddressWrapper = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;
const AddrLine = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  &:last-child { margin-bottom: 0; }
`;
const Remove = styled.span `
  cursor: pointer;
  opacity: .5;
  transition: opacity .5s;
  &:hover { opacity: 1; }
`;
const Title = styled.div `
  margin-left: 30px;
  @media (max-width: 650px) {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
`;

function CartTable({ items, theme, removeItem }) {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    const photos = [...items].map(i => i.orderAttrs.postcardList[0].thumbnail);

    Promise.all(photos.map(getThumbnail)).then(results => {
      setImageData(results)
    })
  }, [items]);

  return (
    <Table>
      <thead>
        <tr>
          <th>Designs</th>
          <th>Recipients</th>
          <th>Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { items.map((d,i) => {
          return (<tr key={`cart${i}`}>
            <td>
              <Flex>
                <Image img={imageData[i]} />
                <Title>
                  {`${d.orderAttrs.postcardList.length} Postcard Design${d.orderAttrs.postcardList.length > 1 ? "s" : ""}`}
                </Title>
              </Flex>
            </td>
            <td>
              <AddressWrapper>
                {d.orderAttrs.addressList.map((a,i) => (
                  <AddrLine key={`addr${i}`}>
                    <div>{a.name}</div>
                    <div style={{ color: "#888" }}>
                      {`${a.address_line1}${a.address_line2 ? ", " : ""}${a.address_line2 || ""}, ${a.address_city}, ${a.address_state}, ${a.address_zip}`}
                    </div>
                  </AddrLine>
                ))}
              </AddressWrapper>
            </td>
            <td style={{ paddingRight: "20px" }}>
              ${(d.quantity*d.price).toFixed(2)}
            </td>
            <td>
              <Remove onClick={() => removeItem(i)}>✕</Remove>
            </td>
          </tr>);
        })}
      </tbody>
    </Table>
  );
}

export default withTheme(CartTable);
import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

import { Paper } from '@material-ui/core';

import CheckoutHeader from './CheckoutHeader';
import Email from './Email';
import CreditCards from './CreditCards';

function CheckoutFlow(props) {
  const { classes, slug, items } = props;

  const [pane, setPane] = useState(0);
  const [email, setEmail] = useState("");
  const [orderID, setOrderID] = useState();
  const [error, setError] = useState();

  const createOrder = () => {
    
    // we only have one item in our store :) "postcard"
    let total = 0;
    if (items.length > 1) {
      total = items.reduce((a, b) => a + b.quantity, 0);
    } else {
      total = items[0].quantity;
    }

    const totalOrder = { type: 'sku', parent: items[0].sku_id, quantity: total };

    let metadata = { status: "Ordered", orderSets: items.length };

    items.forEach((item, index) => {
      metadata[`order-${index}_postcard-length`] = item.orderAttrs.postcardList.length;
      metadata[`order-${index}_id-list`] = JSON.stringify(item.orderAttrs.idList).slice(0, 499);

      // we got bulk upload now
      if (item.orderAttrs.addressList.length <= 10) {
        item.orderAttrs.addressList.forEach((addr, i) => {
          metadata[`order-${index}_address-item-${i}`] = JSON.stringify(addr).slice(0, 499);
        })
      } else {
        metadata[`order-${index}_address-length`] = item.orderAttrs.addressList.length;
      }
    });

    const metadataLength = Object.keys(metadata).length;
    if (metadataLength >= 50) {
      // stripe API has max of 50 items
      let newMetadata = {};
      Object.keys(metadata).forEach((key, i) => {
        if (i < 50)
          newMetadata[key] = metadata[key]
      })
      metadata = newMetadata;
    }

    const postBody = {
      items: [totalOrder],
      metadata: metadata,
      email: email
    };

    fetch("/order/create", {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(postBody)
      }).then((response) => response.json())
      .then((json) => {
        setOrderID(json.id);
        setPane(1);
      })
  }

  const setToken = token => {
    if (!orderID) {
      setError(true)
      return;
    }

    const uuidList = items.map(i => i.orderAttrs.uuidList).flat();

    fetch("/order/pay", {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify({
          id: orderID,
          source: token,
          uuidList,
          email
        })
      }).then((response) => response.json())
      .then((order) => {
        props.updateNumber(0);
        localStorage.setItem(slug, JSON.stringify([]));

        setTimeout(() => {
          props.history.push({
            pathname: '/confirm',
            state: { order }
          });
        }, 100);
      })
  }

  return (
    <>
      <Paper className={classes.paper}>
        <CheckoutHeader text={"Your Email"} classes={classes.heading}
          pane={0} currentPane={pane}
          changePane={() => {
            setPane(0);
          }}
        />
        { pane === 0 ? (
          <Email
            email={email} 
            handleChange={setEmail}
            changePane={() => createOrder()}
          />
        ) : (
          <div className={classes.inputInfo}>
            {email}
          </div>
        )}
      </Paper>
      <Paper className={classes.paper}>
        <CheckoutHeader text={"Payment"} classes={classes.heading}
          pane={1} currentPane={pane}
          changePane={() => setPane(1)}
        />
        { error &&
          <p style={{ color: "#f40" }}>Sorry, an error has occurred. Please refresh the page and try again.</p>
        }
        { !error && pane === 1 && (
          <CreditCards setToken={setToken} />
        )}
      </Paper>
    </>
  );
}
export default withRouter(CheckoutFlow);
import React, { useState } from 'react';
// import styled from 'styled-components';

import Product from './Product';

import { Button } from '@material-ui/core';

function AdminProduct({ product, config, setQuantity }) {
  const [products, setProducts] = useState([]);
  const [ordered, setOrdered] = useState(false);

  const orderPostcards = () => {
    const promises = products.uuidList.map(callAPI);
    Promise.all(promises).then(result => {
      setOrdered(true);
    })
  }

  const callAPI = id => {
    return new Promise(function(resolve, reject) {
      fetch(`/admin-order/${id}`, {
          method: 'GET',
          headers: new Headers({ 'content-type': 'application/json' }),
        }).then((response) => response.json())
        .then(result => {
          console.log(id, result)
          resolve(true);
        });
    });
  }

  return (
    <div>
      <Product
        product={product}
        config={config} 
        updateNumber={setQuantity}
        admin={true}
        setProducts={setProducts}
      />
      <div style={{ textAlign: "center", marginBottom: "50px" }}>
        <p>click add to cart first, please</p>
        { !ordered && (
          <Button variant="contained" color="secondary" onClick={orderPostcards}>
            Order Postcards :)
          </Button>
        )}
        { ordered && <div>Ordered! :) You're all set</div>}
      </div>
    </div>
  );
};
export default AdminProduct;
import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
import withWidth from '@material-ui/core/withWidth';
import { withRouter } from "react-router-dom";

import PageWrapper from '../ui/PageWrapper';
import PostcardCreation from "./PostcardCreation";

function Product(props) {
  const [quantity, setQuantity] = useState(0);
  const [sku_id, setSkuID] = useState();
  const [price, setPrice] = useState();

  const { product, config } = props;

  useEffect(() => {
    if (!props.product.stripe_id) return;

    fetch(`/product-info/${props.product.stripe_id}`)
      .then(res => res.json())
      .then(product => {
        setSkuID(product.data[0].id);
        setPrice(product.data[0].price / 100);
      }).catch(error => console.error('Error:', error));

  }, [props.product.stripe_id]);

  const addToCart = orderAttrs => {
    if (props.admin) {
      props.setProducts(orderAttrs);
      return;
    }

    const slug = `${config.store_slug}_products`;
    let products = JSON.parse(localStorage.getItem(slug));
    products = Array.isArray(products) ? products : [];

    const item = {
      url: `/product/${product.url}`,
      name: product.name,
      sku_id,
      price,
      attr: {},
      orderAttrs,
      quantity
    };

    products.push(item);
    localStorage.setItem(slug, JSON.stringify(products));
    props.updateNumber(products.length);
    props.history.push("/cart");
    
  }


  return (
    <PageWrapper>
      <PostcardCreation
        setQuantity={setQuantity}
        price={price}
        addToCart={addToCart}
      />
    </PageWrapper>
  );
};
export default withWidth()(withRouter(Product));
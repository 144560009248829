import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div `
  max-width: 1100px;
  margin: 84px auto 50px;
  @media (max-width: 640px) {
    margin-top: 74px;
  }
`;

const PageWrapper = props => (
  <Wrapper>
    { props.children}
  </Wrapper>
);
export default PageWrapper;
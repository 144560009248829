import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { getThumbnail } from "../../data/util";

import PageWrapper from '../ui/PageWrapper';
import Paper from '@material-ui/core/Paper';
import { CheckCircle, Alarm, Error } from '@material-ui/icons';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;

  td, th { padding: 4px 10px; text-align: left; }
  td { border: 1px solid #eee; }
`;
const Image = styled.div `
  background-image: url(${props => props.img});
  width: 125px;
  height: 125px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  @media (max-width: 650px) {
    width: 62px;
    height: 62px;
  }
`;

function OrderPage({ match }) {
  const { id } = match.params;
  const [orders, setOrders] = useState([]);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    fetch(`/view-order/${id}`)
      .then(res => res.json())
      .then(result => {
        const sorted = result.sort((a,b) => {
          return new Date(a.printDate) - new Date(b.printDate)
        })
        setOrders(sorted);
      });
  }, [id]);

  useEffect(() => {
    const photos = [...orders].map(i => `${i.postcardFront}/thumbnail.png`);

    Promise.all(photos.map(getThumbnail)).then(results => {
      setImageData(results)
    })
  }, [orders]);

  return (
    <PageWrapper>
      <Paper style={{ padding: "40px", minHeight: "500px" }}>
        <h2 style={{ marginTop: 0, fontWeight: 600 }}>Order {id}</h2>
        <Table>
          <thead>
            <tr>
              <th>Postcard Front</th>
              <th>Postcard Back</th>
              <th>Print Date</th>
              <th>Recipient Address</th>
              <th>Ordered Yet?</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((o,i) => (
              <tr key={`row${i}`}>
                <td>
                  <Image img={imageData[i]} />
                </td>
                <td style={{ maxWidth: "400px" }}>
                  <div>{o.postcardBack.postcard_text}</div>
                  <div>{o.postcardBack.postcard_valediction}</div>
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {o.printDate}
                </td>
                <td>
                  <div>{o.address.name}</div>
                  <div style={{ color: "#888" }}>
                    {`${o.address.address_line1}${o.address.address_line2 ? ", " : ""}${o.address.address_line2 || ""}, ${o.address.address_city}, ${o.address.address_state}, ${o.address.address_zip}`}
                  </div>
                </td>
                <td style={{ textAlign: "center" }}>
                  { (o.status === "paid") && <Alarm /> }
                  { (o.status === "shipped" || o.status === "cleaned") && (
                    <CheckCircle />
                  )}
                  { (o.status === "error") && <Error /> }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Paper>
    </PageWrapper>
  );
}
export default withRouter(OrderPage);
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import PageWrapper from '../ui/PageWrapper';
import { Paper, Grid } from '@material-ui/core';

import CartSmall from '../cart/CartSmall';
import CheckoutFlow from "./CheckoutFlow";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 12 * 2,
    color: theme.palette.text.secondary,
    marginBottom: "20px"
  },
  inputInfo: {
    fontSize: "16px",
    marginTop: "20px",
    color: "black"
  }
});

function Checkout(props) {
  const { classes, config } = props;
  const slug = `${config.store_slug}_products`;
  const parsedItems = JSON.parse(localStorage.getItem(slug));
  const items = parsedItems ? parsedItems : [];

  return (
    <PageWrapper>
      <Grid container className={classes.root} spacing={2} direction={'row-reverse'}>
        <Grid item md={4} xs={12}>
          <Paper className={classes.paper}>
            <CartSmall
              items={items}
              config={config}
            />
          </Paper>
        </Grid>
        <Grid item md={8} xs={12}>
          <CheckoutFlow
            classes={classes}
            slug={slug}
            items={items}
            updateNumber={props.updateNumber}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}
export default withStyles(styles)(Checkout);